.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
  text-align: left;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-list {
  margin-top: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  background: #fff;;
}

::-webkit-scrollbar-thumb {
  background: #f7f7f7;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
  border-radius: 30px;
}

.comment-cell {
  overflow: hidden;          /* Hide overflow */
  text-overflow: ellipsis;  /* Show ellipsis (...) */
  white-space: nowrap;       /* Prevent line breaks */
  max-width: 300px;         /* Set a maximum width */
  display: inline-block;     /* Ensure it acts like an inline element */
}

.comment-cell-full {
  max-width: 300px;         /* Adjust max width */
  overflow: hidden;         /* Hide overflow */
  white-space: nowrap;      /* Prevent line breaks */
  text-overflow: ellipsis;  /* Show ellipsis */
}
.hide-action-column .action-column {
  display: none;
}




/* Custom select styling */
.custom-select {
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f7f7f7;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
}

.custom-select:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
}

.custom-select:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.custom-select option {
  padding: 8px;
  background-color: #f9f9f9;
  color: #333;
}

.custom-select option[value="Yes"] {
  color: #28a745; 
  font-weight: bold;
}

.custom-select option[value="No"] {
  color: #dc3545; 
  font-weight: bold;
}
